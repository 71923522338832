<template>
    <transportistaCrud></transportistaCrud>
</template>

<script>
    import transportistaCrud from '@/components/Transportista/TransportistaCrud';

export default {
    name: 'transportista',
    data() {
        return {};
    },
    components: {
        transportistaCrud
    }
};
</script>
